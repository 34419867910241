<!-- 后厨管理 -->

<script setup></script>

<template>
  <div id="kitchen">
    <button-router></button-router>

    <router-view v-slot="{ Component }">
      <!-- <keep-alive> -->
        <component :is="Component"></component>
      <!-- </keep-alive> -->
    </router-view>
  </div>
</template>

<style lang="scss" scoped>
#kitchen {
  height: 100%;
}
</style>
